<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.roles')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn
          text
          small
          color="green"
          @click="$$router.push({ name: 'admin.role', params: { roleId: 'new', back: { name: $route.name } } })"
          v-if="$hasRole('SUPER-ADMIN')"
        >
          <v-icon>mdi-shield-lock</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          @click="editRole()"
          :disabled="!selected"
          v-if="isGrantedEdit"
        >
          <v-icon>mdi-shield-edit</v-icon>
          <span>{{$t("general.edit")}}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(124)">
        <v-data-table
          dense
          :headers="headers"
          :items="getRoles"
          item-key="id"
          :search="search"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "Roles",
  components: { ViewContainer, Toolbar },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("general.description"), value: "description" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadRoles().then(() => this.loading = false).catch(() => this.loading = false);
  },
  computed: {
    ...mapGetters("roles", ["getRoles", "getSelectedRole"]),
    ...mapState({
      selected() {
        return this.getSelectedRole;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),

    isGrantedEdit() {
      if(!this.notEmptyArray(this.selection)) {
        return false;
      }

      let granted = false;
      switch(this.selection[0].name) {
        case "SUPER-ADMIN":
        case "ADMIN":
          granted = this.$hasRole("SUPER-ADMIN")
          break;
        default:
          granted = this.$hasRole("SUPER-ADMIN") || this.$hasRole("ADMIN");
          break;
      }

      return granted;
    }
  },
  methods: {
    ...mapActions("roles", [
      "loadRoles",
      "setSelectedRole",
      "clearSelectedRole",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedRole(event.item);
      } else if(event.id && this.selected?.id !== event.id) {
        this.setSelectedRole(event);
      } else {
        this.clearSelectedRole();
      }
    },
    editRole() {
      if (this.selected) {
        this.$$router.push({
          name: "role",
          params: { roleId: this.selected.id },
        });
      }
    },
  },
};
</script>
